import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

declare let $: any;

@Component({
  selector: 'app-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss'],
  providers: [AuthService]
})
export class DaterangeComponent implements OnInit {
  value = null;
  profile: any = {};

  @Output() change: any = new EventEmitter();

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
    this.profile = this.authService.logged() || {};

    setTimeout(() => {
      $(() => {
        $('input[name="daterange"]').daterangepicker({
          autoUpdateInput: false,
          opens: 'left'
        }, (start, end, label) => {
          this.value = start.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY');

          this.change.emit({
            start: start.startOf('day').utc().format(),
            end: end.endOf('day').utc().format()
          });
        });
      });
    }, 0);
  }
}
