import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BlankLayoutComponent} from './layouts/blank-layout/blank-layout.component';
import {DashboardLayoutComponent} from './layouts/dashboard-layout/dashboard-layout.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HeaderInterceptor} from './HttpInterceptor';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {AuthGuard} from './guards/auth.guard';
import {ToastrModule} from 'ngx-toastr';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {SharedModule} from './shared.module';
import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import { UrlService } from './utils/url/url.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


// import {BrowserModule} from '@angular/platform-browser';


@NgModule({
  declarations: [
    AppComponent,
    BlankLayoutComponent,
    DashboardLayoutComponent
  ],
  imports: [
    AppRoutingModule,

    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    SharedModule,

    ToastrModule.forRoot(),

    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,

    AngularEditorModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    AuthGuard,
    UrlService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
