import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavComponent } from "./nav/nav.component";
import { FooterComponent } from "./footer/footer.component";
import { RouterModule } from "@angular/router";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ImageGalleryComponent } from "./image-gallery/image-gallery.component";
import { FileSizeModule } from "ngx-filesize";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { StatusComponent } from "./status/status.component";
import { RoleComponent } from "./role/role.component";
import { CustomerInfoComponent } from "./customer-info/customer-info.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PopularComponent } from "./popular/popular.component";
import { DaterangeComponent } from "./daterange/daterange.component";

@NgModule({
  declarations: [
    NavComponent,
    FooterComponent,
    ConfirmDialogComponent,
    ImageGalleryComponent,
    StatusComponent,
    RoleComponent,
    CustomerInfoComponent,
    SidebarComponent,
    UploadDocumentsComponent,
    PopularComponent,
    DaterangeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FileSizeModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
  ],
  exports: [
    NavComponent,
    FooterComponent,
    ImageGalleryComponent,
    StatusComponent,
    RoleComponent,
    SidebarComponent,
    UploadDocumentsComponent,
    PopularComponent,
    DaterangeComponent,
  ],
  entryComponents: [
    NavComponent,
    FooterComponent,
    ConfirmDialogComponent,
    ImageGalleryComponent,
    StatusComponent,
    RoleComponent,
    CustomerInfoComponent,
    SidebarComponent,
    UploadDocumentsComponent,
    PopularComponent,
    DaterangeComponent,
  ],
})
export class ComponentsModule {}
