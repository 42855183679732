import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComponentsModule} from './components/components.module';
import {TrendModule} from 'ngx-trend';
import {AngularEditorModule} from '@kolkov/angular-editor';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TrendModule,
    AngularEditorModule,
    NgbPaginationModule
  ]
})
export class SharedModule {
}
