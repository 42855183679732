import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from "../../services/common/common.service";

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {

  @Input() images: any = [];
  @Output() onChange: any = new EventEmitter();

  constructor(public commonService: CommonService) { }

  ngOnInit() {
  }



  /**
   * Sự kiện khi thay đổi file #thumbnail
   * @param {Event} e
   */
  public onFileChanged(e: Event) {
    let target: HTMLInputElement = e.target as HTMLInputElement;
    for (let i = 0; i < target.files.length; i++) {
      this.commonService.uploadImage(target.files[i], resp => {

        this.images.push(resp.image.path);
        this.onChange.emit(this.images);

        document.getElementById(`upload_file_images`)['value'] = '';
      });
    }
  }

  removeImage(index) {
    this.images.splice(index, 1);
    this.onChange.emit(this.images);
  }

}
