import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BlankLayoutComponent} from './layouts/blank-layout/blank-layout.component';
import {DashboardLayoutComponent} from './layouts/dashboard-layout/dashboard-layout.component';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    component: BlankLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './modules/auth/auth.module#AuthModule',
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'log',
    component: DashboardLayoutComponent,
    loadChildren: './modules/log/log.module#LogModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'article',
    component: DashboardLayoutComponent,
    loadChildren: './modules/article/article.module#ArticleModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    component: DashboardLayoutComponent,
    loadChildren: './modules/admin/admin.module#AdminModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    component: DashboardLayoutComponent,
    loadChildren: './modules/user/user.module#UserModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: DashboardLayoutComponent,
    loadChildren: './modules/profile/profile.module#ProfileModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'country',
    component: DashboardLayoutComponent,
    loadChildren: './modules/country/country.module#CountryModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'service',
    component: DashboardLayoutComponent,
    loadChildren: './modules/service/service.module#ServiceModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'category',
    component: DashboardLayoutComponent,
    loadChildren: './modules/category/category.module#CategoryModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'template',
    component: DashboardLayoutComponent,
    loadChildren: './modules/template/template.module#TemplateModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'file',
    component: DashboardLayoutComponent,
    loadChildren: './modules/file/file.module#FileModule',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
