import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor(public http: HttpClient) {
  }

  list(params: any = {}): any {
    if (params.$page) {
      params.$skip = (params.$page - 1) * (params.$limit);
      delete params.$page;
    }

    const query = $.param(params);
    const url = `${environment.apiUrl}/file-uploads?${query}`;
    return this.http.get(url);
  }

  save(model): any {
    if (model._id) {
      const updateUrl = `${environment.apiUrl}/file-uploads/${model._id}`;
      return this.http.patch(updateUrl, model);
    }

    delete model._id;
    const url = `${environment.apiUrl}/file-uploads`;
    return this.http.post(url, model);
  }

  detail(id): any {
    const url = `${environment.apiUrl}/file-uploads/${id}`;
    return this.http.get(url);
  }

  delete(model): any {
    const url = `${environment.apiUrl}/file-uploads/${model._id}`;
    return this.http.delete(url);
  }
}
