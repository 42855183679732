import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate() {
    if (!window.localStorage.getItem('accessToken')) {
      this.router.navigate(['/auth']);
      return false;
    }

    this.authService.profile().subscribe(
      resp => {
        if (!resp.user) {
          return false;
        }
      },
      err => {
        if (err.status === 401) {
          this.router.navigate(['/auth']);
        }
        return false;
      }
    );
    return true;
  }
}
