import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {CommonService} from '../../services/common/common.service';
import {FileUploadService} from '../../services/file-upload/file-upload.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
  @Input() model: any = null;
  @Output() modelChange = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Input() hideChoose: any = false;
  @Input() accept = "image/*";
  @Input() mimetype = null;

  LOCAL_STORAGE_CODE = null;

  items: any = [];
  total: any = 0;

  params = {$page: 1, $limit: environment.itemsPerPage, $sort: {createdAt: -1}};

  constructor(private toasterService: ToastrService,
              private title: Title,
              private commonService: CommonService,
              private fileUploadService: FileUploadService) {
    this.LOCAL_STORAGE_CODE = `IMAGE_GALLERIES_${this.mimetype}`;

  }

  ngOnInit() {
    if (window[this.LOCAL_STORAGE_CODE] && window[this.LOCAL_STORAGE_CODE].length) {
      this.items = window[this.LOCAL_STORAGE_CODE];
      this.total = this.items.length;
    } else {
      this.loadItems();
    }
  }

  loadItems() {
    this.fileUploadService.list({...this.params, mimetype: this.mimetype}).subscribe(resp => {
      this.items = resp.data;
      this.total = resp.total;

      window[this.LOCAL_STORAGE_CODE] = this.items;
    });
  }

  remove(item) {
    this.fileUploadService.delete(item).subscribe(resp => {
      this.search();
    });
  }

  /**
   * Truy vấn danh sách Value từ hệ thống
   */
  public search(page: number = 1) {
    this.params.$page = page;
    this.loadItems();
  }

  loadImage(item: any) {
    return environment.uploadUrl + '/' + item.filename;
  }

  choose(item) {
    this.model = this.loadImage(item);
    this.modelChange.emit(this.model);
    this.onChange.emit(this.model);
    this.onClose.emit(true);
  }

  /**
   * Sự kiện khi thay đổi file #thumbnail
   * @param {Event} e
   */
  public onFileChanged(e: Event) {
    let target: HTMLInputElement = e.target as HTMLInputElement;
    for (let i = 0; i < target.files.length; i++) {
      this.commonService.uploadImage(target.files[i], resp => {
        document.getElementById(`thumbnail_image_gallery`)['value'] = '';
        this.search(1);
      });
    }
  }

}
