import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: []
})
export class NavComponent implements OnInit {

  profile: any = {};
  contacts: any = [];
  totalContacts: any = 0;

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    this.profile = this.authService.logged() || {};
  }

  logout() {
    window.localStorage.removeItem('accessToken');
    this.router.navigate(['/auth/login']);
  }

}
