import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

import _ from "lodash";
import { UrlService } from "../../utils/url/url.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private urlService: UrlService) {}

  /**
   * Upload hình ảnh lên server
   * @param img
   * @param callback
   */
  uploadImage(img: any, callback) {
    const formData: FormData = new FormData();
    formData.append("image", img, img.name);

    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (ev: ProgressEvent) => {
      // You can handle progress events here if you want to track upload progress
      // (I used an observable<number> to fire back updates to whomever called this upload)
    });

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let data: any = JSON.parse(xhr.responseText);
        callback(data);
      }
    };

    xhr.open("POST", `${environment.apiUrl}/upload/image`, true);
    xhr.send(formData);
  }

  /**
   * Upload hình ảnh lên server
   * @param file
   * @param callback
   */
  uploadFile(file: any, callback) {
    let formData: FormData = new FormData();
    formData.append("file", file, file.name);

    let xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (ev: ProgressEvent) => {
      //You can handle progress events here if you want to track upload progress (I used an observable<number> to fire back updates to whomever called this upload)
    });

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let data: any = JSON.parse(xhr.responseText);
        callback(data);
      }
    };

    xhr.open("POST", `${environment.apiUrl}/upload/file`, true);
    xhr.send(formData);
  }

  loadImage(item: any) {
    return environment.uploadUrl + "/" + item.filename;
  }

  loadFile(item: any) {
    return environment.apiUrl + "/load/file/" + item.filename;
  }

  /**
   * Convert data in CSV (comma separated value) format to a javascript array.
   *
   * Values are separated by a comma, or by a custom one character delimeter.
   * Rows are separated by a new-line character.
   *
   * Leading and trailing spaces and tabs are ignored.
   * Values may optionally be enclosed by double quotes.
   * Values containing a special character (comma's, double-quotes, or new-lines)
   *   must be enclosed by double-quotes.
   * Embedded double-quotes must be represented by a pair of consecutive
   * double-quotes.
   *
   * Example usage:
   *   var csv = '"x", "y", "z"\n12.3, 2.3, 8.7\n4.5, 1.2, -5.6\n';
   *   var array = csv2array(csv);
   *
   * Author: Jos de Jong, 2010
   *
   * @param {string} data      The data in CSV format.
   * @param {string} delimeter [optional] a custom delimeter. Comma ',' by default
   *                           The Delimeter must be a single character.
   * @return {Array} array     A two dimensional array containing the data
   * @throw {String} error     The method throws an error when there is an
   *                           error in the provided data.
   */
  csv2array(data, delimeter) {
    // Retrieve the delimeter
    if (delimeter == undefined) delimeter = ",";
    if (delimeter && delimeter.length > 1) delimeter = ",";

    // initialize variables
    var newline = "\n";
    var eof = "";
    var i = 0;
    var c = data.charAt(i);
    var row = 0;
    var col = 0;
    var array = new Array();

    while (c != eof) {
      // skip whitespaces
      while (c == " " || c == "\t" || c == "\r") {
        c = data.charAt(++i); // read next char
      }

      // get value
      var value = "";
      if (c == '"') {
        // value enclosed by double-quotes
        c = data.charAt(++i);

        do {
          if (c != '"') {
            // read a regular character and go to the next character
            value += c;
            c = data.charAt(++i);
          }

          if (c == '"') {
            // check for escaped double-quote
            var cnext = data.charAt(i + 1);
            if (cnext == '"') {
              // this is an escaped double-quote.
              // Add a double-quote to the value, and move two characters ahead.
              value += '"';
              i += 2;
              c = data.charAt(i);
            }
          }
        } while (c != eof && c != '"');

        if (c == eof) {
          throw "Unexpected end of data, double-quote expected";
        }

        c = data.charAt(++i);
      } else {
        // value without quotes
        while (
          c != eof &&
          c != delimeter &&
          c != newline &&
          c != " " &&
          c != "\t" &&
          c != "\r"
        ) {
          value += c;
          c = data.charAt(++i);
        }
      }

      // add the value to the array
      if (array.length <= row) array.push(new Array());
      array[row].push(value);

      // skip whitespaces
      while (c == " " || c == "\t" || c == "\r") {
        c = data.charAt(++i);
      }

      // go to the next row or column
      if (c == delimeter) {
        // to the next column
        col++;
      } else if (c == newline) {
        // to the next row
        col = 0;
        row++;
      } else if (c != eof) {
        // unexpected character
        throw "Delimiter expected after character " + i;
      }

      // go to the next character
      c = data.charAt(++i);
    }

    return array;
  }

  loadPath(path) {
    return `${environment.uploadUrl}/${path}`;
  }

  isEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  code(str) {
    if (!str) {
      return "";
    }

    return str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
  }

  convertCSVToJSON(str, delimiter = ",") {
    const titles = str.slice(0, str.indexOf("\n")).split(delimiter);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const items = rows.map((row) => {
      const values = row.split(delimiter);
      return titles.reduce(
        (object, curr, i) => ((object[curr] = values[i]), object),
        {}
      );
    });

    const parsedItems = [];
    _.forEach(items, (item) => {
      const newItem = {};

      _.forOwn(item, (itemValue, itemKey) => {
        itemValue = itemValue.replace(/"/g, "").trim();
        itemKey = itemKey.replace(/"/g, "").trim();
        itemKey = this.urlService.slug(itemKey, "_");

        newItem[itemKey] = itemValue;
      });

      parsedItems.push(newItem);
    });

    return parsedItems;
  }

  convertExcelToJSON(arr) {
    const items = [];

    _.forEach(arr, (item, itemKey) => {
      if (itemKey === 0) {
        return;
      }

      const newItem = {};
      _.forEach(item, (itemValue, itemValueKey) => {
        newItem[arr[0][itemValueKey]] = itemValue;
      });

      items.push(newItem);
    });

    console.log(items);

    const parsedItems = [];
    _.forEach(items, (item) => {
      const newItem = {};

      _.forOwn(item, (itemValue, itemKey) => {
        itemValue = `${itemValue}`;
        itemKey = `${itemKey}`;

        itemValue = itemValue.replace(/"/g, "").trim();
        itemKey = itemKey.replace(/"/g, "").trim();
        itemKey = this.urlService.slug(itemKey, "_");

        newItem[itemKey] = itemValue;
      });

      parsedItems.push(newItem);
    });

    return parsedItems;
  }
}
