import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-popular',
  templateUrl: './popular.component.html',
  styleUrls: ['./popular.component.scss']
})
export class PopularComponent implements OnInit {
  @Input() service = null;
  @Input() value = null;
  @Input() field = null;

  item: any = null;

  windowCode = null;

  constructor(public http: HttpClient) {
    this.windowCode = `${this.service}_${this.value}_${this.field}`;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    if (!this.value) {
      return;
    }

    if (window[this.windowCode]) {
      this.item = window[this.windowCode];
      return;
    }

    const url = `${environment.apiUrl}/${this.service}/${this.value && this.value._id ? this.value._id : this.value}`;
    this.http.get(url).subscribe(resp => {
      this.item = resp;
      window[this.windowCode] = this.item;
    });
  }

}
