import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  @Input() role = 'client';

  constructor() {
  }

  ngOnInit() {
  }

}
