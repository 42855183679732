import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [AuthService]
})
export class SidebarComponent implements OnInit {
  public profile: any = {};
  public $intervalProfile = null;

  constructor(private router: Router,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.getProfile();

    this.$intervalProfile = setInterval(() => {
      this.getProfile();
    }, 60000);
  }

  getProfile() {
    this.authService.profile()
      .subscribe(resp => {
        this.profile = resp;
      }, () => {
        this.logout();

        if (this.$intervalProfile) {
          clearInterval(this.$intervalProfile);
        }
      });
  }

  logout() {
    window.localStorage.removeItem('accessToken');
    this.router.navigate(['/auth/login']);
  }
}
