import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

import {JwtHelperService} from '@auth0/angular-jwt';
import {UserService} from '../user/user.service';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public http: HttpClient,
              public userService: UserService) {
  }

  login(model): any {
    const url = `${environment.apiUrl}/authentication`;
    return this.http.post(url, model);
  }

  verify(model): any {
    const url = `${environment.apiUrl}/verify-email`;
    return this.http.post(url, model);
  }

  profile(): any {
    const loggedUser = this.logged();
    return this.userService.detail(loggedUser._id);
  }


  /**
   *
   */
  logged() {
    const helper = new JwtHelperService();
    return JSON.parse(window.localStorage.getItem('loggedUser'));
  }
}
